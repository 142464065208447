"use strict";
$(function () {
    $('#edit_panel').on("click", function () {
        $('#id_panelist').hide();
        $('#selected_panelists').remove();
        $('<div id="selected_panelists_panel" class="card"><ul id="selected_panelists" class="list-group list-group-flush"></div>')
            .insertAfter('#id_panelist');
        $('#unselected_panelists').remove();
        $('<select id="unselected_panelists" class="form-control form-select"></select>')
            .append('<option selected>Add a panelist</option>')
            .on("change", function () {
            var panelist = $('#unselected_panelists option:selected');
            if (!panelist.val())
                return;
            panelist.remove();
            console.log(panelist);
            $('#id_panelist option[value="' + panelist.val() + '"]')
                .attr('selected', 'selected');
            addSelected(panelist);
            $('#unselected_panelists option').first().attr('selected', 'selected');
        })
            .insertAfter('#selected_panelists_panel');
        function addSelected(option) {
            $('<li class="list-group-item clearfix"></li>')
                .text(option.text())
                .prop('value', option.val())
                .append(' ', $('<span class="float-end"><button class="btn btn-xs btn-danger"><span class="bi bi-trash ptk_remove_panelist"></span></button></span>')
                .on("click", function (e) {
                var panelist = $(this).parent();
                panelist.remove();
                $('#id_panelist option[value="' + panelist.prop('value') + '"]')
                    .removeAttr('selected');
                addUnselected(panelist);
                e.stopPropagation();
            }))
                .appendTo('#selected_panelists');
        }
        function addUnselected(option) {
            $('<option></option>')
                .text(option.text())
                .val(option.val())
                .appendTo('#unselected_panelists');
        }
        $('#id_panelist option').each(function () {
            var panelist = $(this);
            if (panelist.prop('selected')) {
                addSelected(panelist);
            }
            else {
                addUnselected(panelist);
            }
        });
    });
    $('#id_panelist_existing').on("change", function () {
        console.log("Changed.");
        if ($('#id_panelist_existing option:selected').val()) {
            $('.new-panelist').hide();
        }
        else {
            $('.new-panelist').show();
        }
    });
});
